import React, { useEffect } from 'react';

import { Button, ButtonPriority, HoverStyles } from 'wix-ui-tpa/cssVars';
import { classes, st } from './LoadServicesButton.st.css';
import { useStyles } from '@wix/tpa-settings/react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import stylesParams from '../../../stylesParams';
import { SpinnerWrapper } from '@wix/bookings-viewer-ui';
import { useWidgetViewModel } from '../../hooks/useWidgetViewModel';

export type LoadServicesButtonProps = {
  text: string;
  className?: string;
  onClick: () => void;
  dataHook: string;
  href: string;
};

export const DataHooks = {
  BUTTON: 'load-services-button-button',
  SPINNER: 'load-services-button-spinner',
};

export const LoadServicesButton = ({
  href,
  text,
  className,
  onClick,
  dataHook,
}: LoadServicesButtonProps) => {
  const [loading, setLoading] = React.useState(false);
  const styles = useStyles();

  const { isMobile } = useEnvironment();
  const { servicesPagingMetadata } = useWidgetViewModel();
  const spinnerDiameter = styles.get(stylesParams.loadServicesButtonFont).size;
  const { experiments } = useExperiments();
  const isButtonHoverContrastEnabled = experiments.enabled(
    'specs.bookings.buttonHoverContrast',
  );

  useEffect(() => {
    setLoading(false);
  }, [servicesPagingMetadata]);

  return (
    <div data-hook={dataHook} className={st(classes.root, className)}>
      <a style={{ display: 'none' }} href={href}>
        {text}
      </a>
      <Button
        upgrade
        data-hook={DataHooks.BUTTON}
        className={st(classes.button)}
        onClick={() => {
          setLoading(true);
          onClick();
        }}
        priority={ButtonPriority.secondary}
        fullWidth={isMobile}
        hoverStyle={
          isButtonHoverContrastEnabled ? HoverStyles.underline : undefined
        }
      >
        {loading ? (
          <div className={classes.spinnerWrapper}>
            <SpinnerWrapper
              className={classes.spinner}
              diameter={spinnerDiameter}
              data-hook={DataHooks.SPINNER}
            />
          </div>
        ) : (
          text
        )}
      </Button>
    </div>
  );
};
