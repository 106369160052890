import { useExperiments } from '@wix/yoshi-flow-editor';
import { useWidgetViewModel } from '../Widget/hooks/useWidgetViewModel';

export const useLayoutPerBpInWidget = () => {
  const { experiments } = useExperiments();
  const { shouldWorkWithAppSettings } = useWidgetViewModel();
  return {
    layoutStylePerBpEnable:
      experiments.enabled('specs.bookings.layoutStylePerBreakPoint') &&
      !shouldWorkWithAppSettings,
    layoutPbpPhase2Enabled:
      experiments.enabled('specs.bookings.layoutPerBreakPointPhase2') &&
      !shouldWorkWithAppSettings,
  };
};
